@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-black;
    font-family: 'dazzed', sans-serif;
  }

  // links
  a:hover {
    @apply no-underline opacity-80;
  }

  // typo
  h1 {
    font-size: 48px;

    @screen lg {
      font-size: 80px;
    }
  }
  h2 {
    font-size: 48px;
    margin-top: 4rem;
    margin-bottom: 1.5rem;

    @screen lg {
      // font-size: 72px;
      // margin-bottom: 42px;
    }
  }
  h3 {
    font-size: 32px;
    margin-top: 3rem;
    margin-bottom: 1.5rem;

    @screen lg {
      font-size: 48px;
    }
  }
  p,
  li {
    font-size: 14px;
    letter-spacing: 0.025em;
    margin-bottom: 1rem;

    @screen lg {
      font-size: 18px;
    }
  }
  img,
  video {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  p {
    code {
      background-color: #f1f1f1;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      font-weight: normal !important;
      // make the before and after empty to get rid of the backticks
      &::before,
      &::after {
        content: '' !important;
      }
    }
  }
  pre {
    // needs to overwrite .prose pre
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 0.5rem !important;

    & > pre {
      background-color: #f6f8fa !important;
      padding: 20px !important;
    }
  }
  button[type='submit'],
  button a {
    text-transform: uppercase;
    font-family: dazzed-semibold, sans-serif;
  }
}

// fonts
@font-face {
  font-family: 'dazzed';
  src: url('../../public/fonts/dazzed-medium.woff2') format('woff2'),
    url('../../public/fonts/dazzed-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'dazzed-semibold';
  src: url('../../public/fonts/dazzed-semibold.woff2') format('woff2'),
    url('../../public/fonts/dazzed-semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
