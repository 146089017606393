/* The following block can be included in a custom.scss */

/* make the customizations */
$jumbotron-bg: #e4e8f0;

$theme-colors: (
  'light': white,
);

$body-color: #353839;
$link-color: #339999;
$link-hover-color: #336666;

.companies svg,
.projects svg {
  width: 176px;
  opacity: 0.5;
  filter: grayscale(1);
  margin: 10px 30px;
  text-align: center;
}

.companies svg:hover,
.projects svg:hover {
  opacity: 1;
  filter: grayscale(0);
}

.list-group-item {
  text-align: left;
  border: none;
}

.card > .list-group {
  border: none;
}

.list-group-item svg {
  position: relative;
  top: -2px;
  margin: auto;
  text-align: center;
  display: inline-block;
}

.list-group-item.not-feature {
  opacity: 0.4;
  filter: grayscale(100%);
}

.form-group.row {
  margin-bottom: 0.5rem;
}

#team svg {
  filter: grayscale(100%);
}

#team .member {
  margin-bottom: 30px;
}

#team .member img.head-shot {
  border-radius: 50%;
  margin-bottom: 10px;
}

#products .card {
  border: 1px solid #e6ecf1;
  border-radius: 0.8rem;
}

#products .card.pro {
  border-color: #0d58c9;
  background-color: #eaeff7;
}

#products .card.pro .list-group-item {
  background-color: #eaeff7;
}

.carousel-indicators {
  bottom: -50px;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: lightgray;
}

.blog h2 {
  margin-bottom: 12px;
  margin-top: 48px;
}

.blog p {
  line-height: 30px;
  text-align: justify;
}

.build-faster-image-container {
  padding: 16px;
  span {
    overflow: visible !important;
  }
  img {
    box-shadow: rgba(20, 29, 31, 0.1) 0px 20px 40px, rgba(20, 29, 31, 0.1) 0px 10px 20px,
      rgba(20, 29, 31, 0.1) 0px 0px 0px;
    border-radius: 1rem;
  }
}
